import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import Layout from '../../components/layout'
import Header from '../../components/header'
import Container from '../../components/container'
import StoryIntro from '../../components/story-intro'
import Row from '../../components/row'
import Column from '../../components/column'

SwiperCore.use([Navigation, Pagination])

const PageHeader = <Header title="Multifunktions-Touchlenkrad" />

const TouchLenkradPage = ({ location }) => {
    const images = useStaticQuery(graphql`
      {
        demonstrator: allFile(filter: {relativeDirectory: {eq: "stories/touch-lenkrad/demonstrator"}}, sort: {fields: name}) {
          nodes {
            childImageSharp {
              gatsbyImageData(
                width: 1050
                height: 650
                transformOptions: {
                  cropFocus: CENTER
                }
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
            name
          }
        }
        iddrei: allFile(filter: {relativeDirectory: {eq: "stories/touch-lenkrad/id"}}, sort: {fields: name}) {
          nodes {
            childImageSharp {
              gatsbyImageData(
                width: 1050
                height: 650
                transformOptions: {
                  cropFocus: CENTER
                }
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
            name
          }
        }
      }
    `)

    return (
        <Layout translatedPageLinks={[{ code: 'de', link: '/de/story/multifunktions-lenkrad/' }, { code: 'en', link: '/en/story/touch-steering-wheel/' }]} pageLang="de" pageTitle="Das Multifunktions-Touchlenkrad" pageHeader={PageHeader} location={location}>
            <Container>
                <section>
                    <StoryIntro>
                        <p>
                            Bei Innovationen hat GE-T das Steuer fest im Griff.
                            Idee und Konzept des haptischen Feedbacks des Lenkrades der ID.-Familie und weiterer Premium Modelle der Marke Volkswagen stammen von uns.
                        </p>
                    </StoryIntro>
                    <p>
                        Von der Idee über diverse Prototypen bis hin zum aktuellen Serienprodukt begleitete GE-T die Entwicklung des Multifunktions-Lenkrades mit haptischem Feedback.
                        Die direkte Rückmeldung an den Nutzer ermöglicht die sichere Blindbedienung und ist Grundlage für einen Einsatz im Automobil.
                        Gemeinsam mit Volkswagen und Audi ist GE-T Inhaber des entsprechenden Patents.
                    </p>
                    <p>
                        Die erste Touch-Oberfläche mit haptischem Feedback auf einem Lenkrad entwickelte GE-T bereits 2016 für die Volkswagen Studie des Budd-e, einem Vorbild des kommenden ID.Buzz.
                        Doch auch damit wird die Entwicklung moderner Lenkräder nicht enden.
                    </p>
                    <Row>
                        <Column columnWidth={2}>
                            {/* eslint-disable-next-line */}
                            <GatsbyImage image={getImage(images.demonstrator.nodes[1])} alt="" />
                        </Column>
                        <Column columnWidth={2}>
                            {/* eslint-disable-next-line */}
                            <GatsbyImage image={getImage(images.demonstrator.nodes[0])} alt="" />
                        </Column>
                    </Row>
                    <p>
                        Unsere Vision für das „Lenkrad 2030“ lässt nicht nur die traditionelle Form eines Rades hinter sich.
                        Dank Drive-by-Wire Technologie wird eine moderne Steuerform möglich, die ideal für die Herausforderungen des autonomen Fahrens geeignet ist.
                        Puls-Sensorik und vegane Materialien ergänzen den innovativen Ansatz.
                        Teil der permanenten GE-T Lenkrad-Forschung und -Entwicklung ist zudem die Hands-On-Detection.
                        Diese Vorgabe des Gesetzgebers haben wir besonders kundenfreundlich realisiert.
                        Sie ist inzwischen nicht nur 20 Prozent günstiger als bisherige Lösungen, sondern fungiert gleichzeitig als Lenkradheizung: Ein Bauteil übernimmt zwei Aufgaben und verbindet Sicherheit mit Komfort.
                    </p>
                    <Swiper
                        navigation
                        loop
                        pagination={{ clickable: true }}
                    >
                        {/* eslint-disable-next-line */}
                        {images.iddrei.nodes.map((image) => <SwiperSlide key={image.name}><GatsbyImage image={getImage(image)} alt="" /></SwiperSlide>)}
                    </Swiper>
                </section>
            </Container>
        </Layout>
    )
}

export default TouchLenkradPage